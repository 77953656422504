const initialState = {
    isAuthenticated: false,
    user: null,
    token: localStorage.getItem('token') || null,
    userId: localStorage.getItem('userId') || null,
    userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null,
    imageUrl: localStorage.getItem('profilePicture') || '',
    loading: false,
    error: null,
    vehicles: null,
    trips:null,
    terminals:null,
    transportCompanies:null,
    driver:null,
    ticket:null,
    homeData:null,
    driverTrips:null,
    message:null,
    allTravellers:null,
    payments:null,
    faqs:[],
    notifications:[]
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SIGNUP_SUCCESS':
            case 'LOGIN_SUCCESS':
                return {
                    ...state,
                    isAuthenticated: true,
                    user: action.payload,
                    token: action.payload.token,
                    userId: action.payload.userId,
                    message: action.payload.message,
                    loading: false,
                    error: null, // Reset error on success
                };
        case 'FETCH_USER_SUCCESS':
            return {
                ...state,
                userData: action.payload,
                loading: false,
                error: null, 
                message: action.payload.message,
            };

        case 'VIEW_ALL_TRAVELLERS_ADMIN_SUCCESS':
                return {
                    ...state,
                    allTravellers: action.payload,
                    loading: false,
                    error: null, 
                    message: action.payload.message,
                };
        case 'VIEW_COMPANY_TICKETS_SUCCESS':
                return {
                    ...state,
                    ticket: action.payload,
                    loading: false,
                    message: action.payload.message,
                    error: null, // Reset error on success
                };
        case 'GET_NOTIFICATIONS_SUCCESS':
                return {
                    ...state,
                    notifications: action.payload,
                    loading: false,
                    message: action.payload,
                    error: null, // Reset error on success
                };
        case 'CONTACT_US_SUCCESS':
            return {
                ...state,
                loading: false,
                    message: action.payload.message,
                    error: null, // Reset error on success
            };
        case 'RESET_PASSWORD_SUCCESS':
        case 'FORGET_PASSWORD_SUCCESS':
        case 'ADD_FAQS_SUCCESS':
            return {
                ...state,
                loading: false,
                    message: action.payload.message,
                    error: null, // Reset error on success
            };
        case 'VIEW_TERMINAL_SUCCESS':
        case 'ADD_TERMINAL_SUCCESS':
                return {
                    ...state,
                    terminals:action.payload,
                    loading: false,
                    message: action.payload.message,
                    error: null, // Reset error on success
                };
        case 'ADD_VEHICLE_SUCCESS':
        case 'VIEW_VEHICLES_COMPANY_SUCCESS':
        case 'VIEW_VEHICLE_SUCCESS':
            return {
                ...state,
                vehicles:action.payload,
                loading: false,
                    message: action.payload.message,
                    error: null, // Reset error on success
            };
            case 'VIEW_DRIVER_COMPANY_SUCCESS':
                return {
                    ...state,
                    driver:action.payload,
                    loading: false,
                    message: action.payload.message,
                    error: null, // Reset error on success
                };
                case 'VIEW_COMPANY_HOME_SUCCESS':
                    return {
                        ...state,
                        homeData:action.payload,
                        loading: false,
                    message: action.payload.message,
                    error: null, // Reset error on success
                    };
            case 'ADD_TRIPS_SUCCESS':
            case 'VIEW_TRIPS_SUCCESS':
                return {
                    ...state,
                    trips:action.payload,
                    loading: false,
                    message: action.payload.message,
                    error: null, // Reset error on success
                };
        case 'VIEW_DRIVERTRIPS_SUCCESS':
                    return {
                        ...state,
                        driverTrips:action.payload,
                        loading: false,
                        error: null, // Reset error on success
                    };
        case '  VIEW_ALL_PAYMENT_ADMIN_SUCCESS':
                        return {
                            ...state,
                            payments:action.payload,
                            loading: false,
                            error: null, // Reset error on success
                    };
        case 'VIEW_FAQS_ADMIN_SUCCESS':
                        return {
                            ...state,
                            faqs:action.payload,
                            loading: false,
                            error: null, // Reset error on success
                    };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                token: null,
                userId: null,
                userData: null,
                driverEmail:null,
                imageUrl: '',
                // message: action.payload.message,
            };
        case 'UPLOAD_PROFILE_PICTURE_REQUEST':
        case 'TRACK_TRIPS_SUCCESS':
        case 'START_TRIPS_SUCCESS':
        case 'UPDATE_ADMIN_DATA_SUCCESS':
            return {
                ...state,
                loading: true,
                message: action.payload.message,
                error: null,
            };
        case 'VIEW_TICKETS_ADMIN_SUCCESS':
            return {
                ...state,
                ticket:action.payload,
                loading:false,
                error:null,
            }
        case 'UPLOAD_PROFILE_PICTURE_SUCCESS':
            return {
                ...state,
                loading: false,
                imageUrl: action.payload,
                    message: action.payload.message,
                    error: null, // Reset error on success
            };
        case 'VIEW_ALL_COMPANIES_SUCCESS':
                return {
                    ...state,
                    transportCompanies:action.payload,
                    loading: false,
                    message: action.payload.message,
                    error: null, // Reset error on success
                };
        case 'APPROVE_CLIENT_MEMBERSHIP_SUCCESS':
            return{
                ...state,
                loading:false,
                message:action.payload,
                error:null,
            };
        
        case 'SIGNUP_FAILURE':
        case 'LOGIN_FAILURE':
        case 'FETCH_USER_FAILURE':
        case 'CONTACT_US_FAILURE':
        case 'RESET_PASSWORD_FAILURE':
        case 'FORGET_PASSWORD_FAILURE':
        case 'UPLOAD_PROFILE_PICTURE_FAILURE':
        case 'ADD_TERMINALS_FAILURE':
        case 'VIEW_TERMINAL_FAILURE':
        case 'ADD_VEHICLE_FAILURE':
        case 'VIEW_VEHICLES_COMPANY_FAILURE':
        case 'VIEW_DRIVER_COMPANY_FAILURE':
        case 'VIEW_VEHICLE_FAILURE':
        case 'ADD_TRIPS_FAILURE':
        case 'VIEW_ALL_COMPANIES_FAILURE':
        case 'VIEW_TRIPS_FAILURE':
         case 'VIEW_DRIVERTRIPS_FAILURE':
        case 'TRACK_TRIPS_FAILURE':
        case 'VIEW_COMPANY_TICKETS_FAILURE':
        case 'VIEW_COMAPNY_HOME_FAILURE':
        case 'VIEW_ALL_TRAVELLERS_ADMIN_FAILURE':
        case  'APPROVE_CLIENT_MEMBERSHIP_FAILURE' :
        case 'START_TRIPS_FAILURE':
        case 'VIEW_TICKETS_ADMIN_FAILURE':
        case 'VIEW_ALL_PAYMENT_ADMIN_FAILURE':
        case "VIEW_FAQS_ADMIN_FAILURE":
        case 'ADD_FAQS_FAILURE':
        case 'UPDATE_ADMIN_DATA_FAILURE':
        case "GET_NOTIFICATIONS_FAILURE":
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        
        default:
            return state;
    }
};

export default authReducer;
