import isLoggedInReducer from "./isLoggedIn";
import authReducer from "./authReducers";
import { combineReducers } from "redux";

const allReducers = combineReducers({
    loggedReducer: isLoggedInReducer,  // Corrected the typo
    auth: authReducer,  // Better naming, so you can access it as state.auth in useSelector
});

export default allReducers;
